import React from "react"

import Story from "../../components/StyleGuide/components/Story"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"

const StoryPage = () => (
  <Layout backgroundColor={"white"} navColor={"black"}>
    <SEO
      title="Thesis | Our brand story"
      keywords={[
        "style guide",
        "brand guide",
        "thesis agency",
        "portland agency",
        "marketing agency",
      ]}
      description="So you want to spread the word about Thesis? Here's everything you need to talk on behalf of Thesis, a digital agency in Portland, OR."
    />
    <Story />
  </Layout>
)

export default StoryPage
