import React, { useState } from "react"
import styles from "./../../styles.module.less"
import Scrollspy from "react-scrollspy"
import { Link } from "gatsby"
import Reveal from "react-reveal/Reveal"

// export default class Story extends Component {
const Story = () => {
  const [showRange, setShowRange] = useState(false)
  const [showRelevance, setShowRelevance] = useState(false)

  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <div>
      <Reveal>
        <div
          id="story"
          className={[
            styles.thesisbrandguidelinesstory,
            styles.animated,
            styles.fadeInUp,
            styles.animatedFadeInUp,
          ].join(" ")}
        >
          <div className={styles.p1}>
            <div className={styles.headline}>Our story.</div>
            <div className={styles.headline2}>
              Your guide to <i>talking</i> on behalf of Thesis.
            </div>
            <Link to="/styleguide" className={styles.back_btn}>
              <svg
                preserveAspectRatio="none"
                viewBox="0 0 17 16"
                className={styles.arrow}
              >
                <path d="M 8.999984741210938 0 L 16.99998474121094 8 L 8.999984741210938 16 L 7.599990844726563 14.5999755859375 L 13.19999694824219 9 L 0 9 L 0 7 L 13.19998168945313 7 L 7.5999755859375 1.4000244140625 L 8.999984741210938 0 Z" />
              </svg>
              <div className={styles.back}>Back</div>
            </Link>
          </div>

          <nav className={styles.stickySideMenuStory}>
            <Scrollspy
              items={[
                "tagline",
                "elevator-pitch",
                "values",
                "purpose",
                "voice",
                "name",
                "gritty",
              ]}
              currentClassName={styles.isCurrent}
            >
              <li>
                <a className={styles.logo} href="#tagline" tabIndex="0">
                  Tagline
                </a>
              </li>
              <li>
                <a
                  className={styles.typography}
                  href="#elevator-pitch"
                  tabIndex="0"
                >
                  Elevator Pitch
                </a>
              </li>
              <li>
                <a className={styles.color} href="#values" tabIndex="0">
                  Values
                </a>
              </li>
              <li>
                {" "}
                <a className={styles.templates} href="#purpose" tabIndex="0">
                  Purpose
                </a>
              </li>
              <li>
                {" "}
                <a className={styles.templates} href="#voice" tabIndex="0">
                  Voice
                </a>
              </li>
              <li>
                {" "}
                <a className={styles.templates} href="#name" tabIndex="0">
                  Name
                </a>
              </li>
              <li>
                {" "}
                <a className={styles.templates} href="#gritty" tabIndex="0">
                  Grammar
                </a>
              </li>
            </Scrollspy>
          </nav>

          <section className={styles.tagline}>
            <div id="tagline" className={styles.spacer}>
              <div className={styles.wordsBg}></div>
            </div>
          </section>

          <section className={styles.positioning}>
            <div className={styles.mainPar}>
              <h2>Positioning</h2>
              <h4>A digital agency</h4>
            </div>
            <div className={styles.twoBlocks}>
              <div
                id="elevator-pitch"
                className={`${styles.spacer} ${styles.elevatorPitch}`}
              >
                {/* Empty p for correct navigation alignment */}
                <p></p>
                <div className={styles.elevator}>
                  <h2>Elevator pitch</h2>
                  <p>
                    We’re a digital agency whose versatile work has the
                    <em
                      onMouseOver={() => setShowRange(true)}
                      onMouseOut={() => setShowRange(false)}
                      style={{ color: "#C577A3" }}
                    >
                      {" "}
                      range
                    </em>{" "}
                    to meet people where they are and the{" "}
                    <em
                      onMouseOver={() => setShowRelevance(true)}
                      onMouseOut={() => setShowRelevance(false)}
                      style={{ color: "#C577A3" }}
                    >
                      relevance
                    </em>{" "}
                    to engage people as they are.
                  </p>
                  {showRange && (
                    <div hidden={showRange} className={styles.floatBlock}>
                      <span style={{ paddingBottom: 3 }}>Range:</span> We have
                      the capability to execute across a spectrum of digital
                      media. This gives us the mobility and flexibility to
                      create targeted, direct-to- consumer digital products that
                      reach people, no matter where they’re hanging out.
                    </div>
                  )}
                  {showRelevance && (
                    <div hidden={showRelevance} className={styles.floatBlock}>
                      <span style={{ paddingBottom: 3 }}>Relevance:</span> We
                      use data to know exactly who we’re talking to. This allows
                      us to tailor hyper-relevant messaging that people care to
                      engage with because it speaks to them like the humans that
                      they are.
                    </div>
                  )}
                </div>
              </div>
              <div id="values" className={styles.spacer}>
                {/* Empty p for correct navigation alignment */}
                <p></p>
                <div className={styles.values}>
                  <h2>Values</h2>
                  <p>
                    1. Be lean, take action.
                    <br />
                    2. Connect, conflict, and embrace.
                    <br />
                    3. Make your case with data.
                  </p>
                </div>
              </div>
              <p id="purpose" className={styles.spacer}>
                {/* Empty p for correct navigation alignment */}
                <p></p>
                <div className={styles.purpose}>
                  <h2>Purpose</h2>
                  <p>
                    Find the tension.
                    <br />
                    Make it right.
                    <br />
                    Deliver. That. Delight.
                  </p>
                </div>
              </p>
            </div>
          </section>

          <section className={styles.voice}>
            <p id="voice" className={styles.spacer}>
              <div className={styles.mainPar}>
                <h2>Voice and tone.</h2>
                <h4>
                  Talk the jargon-less talk.
                  <br />
                  Be honest. Be direct. <br />
                  Keep it light.
                </h4>
              </div>
            </p>

            <div className={styles.thesisIsThis}>
              <div>
                <h2 className={styles.this}>
                  Thesis is <span>this</span>
                </h2>
                <div>
                  Funny <br />
                  Frank
                  <br />
                  Kind
                  <br />
                  Smart
                  <br />
                  Enthusiastic
                  <br />
                  Discerning
                  <br />
                  Thoughtful
                  <br />
                  Savvy
                  <br />
                  Decisive
                </div>
              </div>
              <div className={styles.not}>
                <h2>
                  Not <span>that</span>
                </h2>
                <div>
                  Silly
                  <br />
                  Cold
                  <br />
                  Schmaltzy <br />
                  Smug
                  <br />
                  Careless
                  <br />
                  Stuck Up
                  <br />
                  Precious
                  <br />
                  Arrogant
                  <br />
                  Overbearing
                </div>
              </div>
            </div>
          </section>

          <section className={styles.nerdy}>
            <div className={styles.mainPar} id="name">
              <h2>
                Getting down
                <br />
                and nerdy.
              </h2>
            </div>
            <div className={styles.twoCol}>
              <div className={styles.spell}>
                <div className={styles.word}>
                  <span className={styles.thesis}>Thesis</span>
                  <span className={styles.notThesis}>thesis</span>
                </div>
                <div className={styles.word}>
                  <span className={styles.thesis}>Thesis’s</span>
                  <span className={styles.notThesis}>Thesis’</span>
                </div>
                <div className={styles.word}>
                  <span className={styles.thesis}>Thesis</span>
                  <span className={styles.notThesis}>Theses</span>
                </div>
                <div className={styles.word}>Thesis.agency</div>
                <div className={styles.word}>Thesis, Inc.</div>
              </div>
              <div>
                <h2>The name</h2>
                <div className={styles.rule}>
                  <h3>Case</h3>
                  <p>
                    Spell Thesis with a capital T. It’s only lowercase in the
                    logo.
                  </p>
                  <span>
                    As in: If you need a good digital agency, Thesis is the real
                    deal.
                  </span>
                </div>
                <div className={styles.rule}>
                  <h3>Possessive</h3>
                  <p>The possessive of Thesis is Thesis’s. Not Thesis’.</p>
                  <span>
                    As in: In fact, Thesis’s employees are some of the brightest
                    folks around.
                  </span>
                </div>
                <div className={styles.rule}>
                  <h3>Plural</h3>
                  <p>The plural of Thesis is Theses.</p>
                  <span>
                    As in: They don’t just bring one idea to the table—but
                    several strong theses.
                  </span>
                </div>
                <div className={styles.rule}>
                  <h3>Website</h3>
                  <p>
                    The url is Thesis.agency. That’s a capital T; lowercase a.
                  </p>
                  <span>
                    As in: You can learn more about their work at Thesis.agency.
                  </span>
                </div>
                <div className={styles.rule}>
                  <h3>Legal</h3>
                  <p>The full legal name is Thesis, Inc.</p>
                  <span>
                    As in: Then address the SOW to Thesis, Inc. It’ll be smooth
                    sailing from there.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.gritty}>
              <p id="gritty" className={styles.spacer}>
                {/* Empty p for correct navigation alignment */}
                <p></p>
                <h2>The gritty grammar.</h2>
                <div className={styles.blocks}>
                  <div className={styles.rules}>
                    <div className={styles.rule}>
                      <h3>Em dashes</h3>
                      <p>Keep ‘em cozy.</p>
                      <span>
                        As in: Those people at Thesis⁠—a digital agency in
                        Portland⁠—are good people.
                      </span>
                    </div>
                    <div className={styles.rule}>
                      <h3>Ellipses</h3>
                      <p>Let ‘em breathe.</p>
                      <span>
                        As in: Thesis’s manifesto quotes Bukowski … yes, that
                        Bukowski.
                      </span>
                    </div>
                    <div className={styles.rule}>
                      <h3>Time of day</h3>
                      <p>Xpm—lowercase; no space.</p>
                      <span>
                        As in: We’ll work until 1am for our partners, but we’ll
                        drink at 1pm for ourselves.
                      </span>
                    </div>
                    <div className={styles.rule}>
                      <h3>Dates</h3>
                      <p>
                        &#8226; No ordinal indicator
                        <br />
                        (1<strike>st</strike>, 2<strike>nd</strike>, 3
                        <strike>rd</strike>, 4<strike>th</strike>)
                        <br />
                        &#8226; No period when abbreviated <br />
                        (Jan, Feb, Mar)
                        <br />
                        &#8226; Slashes separate digits <br />
                        (12/31/20 not 12.31.20 or 12-31-20)
                      </p>
                      <span>As in: Dec 31, 2020</span>
                    </div>
                    <div className={styles.rule}>
                      <h3>Numbers</h3>
                      <p>Spell up to nine, then it’s digits.</p>
                      <span>
                        As in: one, two, three, four, five, six, seven, eight,
                        nine, 10, 11, 12 …
                      </span>
                    </div>
                  </div>
                  <div className={styles.rules}>
                    <div className={styles.rule}>
                      <h3>Acronyms</h3>
                      <p>All caps; no periods. Exception: i.e. and e.g.</p>
                      <span>
                        As in: You don’t need an MFA to see that Thesis’s
                        creative is solid (e.g., see their slick website).
                      </span>
                    </div>
                    <div className={styles.rule}>
                      <h3>This ~slash~ this</h3>
                      <p>Put a space on each side of the slash.</p>
                      <span>
                        As in: Thesis is a full-service creative / marketing /
                        digital agency.
                        <br />
                        Known exceptions: and/or, A/B testing
                      </span>
                    </div>
                    <div className={styles.rule}>
                      <h3>And, &, and +</h3>
                      <p>
                        &#8226; Spell <i>and</i> when space allows (“&” or “+”
                        is not a 1:1 substitution)
                      </p>
                      <p>
                        &#8226; True lock-ups can use “&” … but never “+” (e.g.,
                        Thelma & Louise)
                      </p>
                      <p>
                        &#8226; If forcing a line break, the “&” is always on
                        the second line with the second item of the pair
                      </p>
                      <span>
                        As in: <br />
                        Thelma
                        <br /> & Louise
                      </span>
                    </div>
                    <div className={styles.rule}>
                      <h3>Quotations</h3>
                      <p>Double is better.</p>
                      <span>
                        As in: There’s a “right” way and a ‘wrong’ way to do any
                        one thing. Go with right.
                      </span>
                    </div>
                    <div className={styles.rule}>
                      <h3>Emojis</h3>
                      <p>
                        Use ‘em! But remember, they take the place of the
                        punctuation.
                      </p>
                      <span>As in: Thesis how we do it 🎤</span>
                    </div>
                    <div className={styles.rule}>
                      <h3>Exclamations</h3>
                      <p>You get one per page. Use them wisely.</p>
                      <span>
                        As in: We can’t give you an example, we already spent
                        that chip above.
                      </span>
                    </div>
                  </div>
                </div>
              </p>
            </div>
          </section>
          <Link to="/styleguide/style" className={styles.bottomCta}>
            <div className={styles.back}>Our style</div>
            <div>
              <svg
                preserveAspectRatio="none"
                viewBox="0 0 17 16"
                className={styles.arrow}
              >
                <path d="M 8.999984741210938 0 L 16.99998474121094 8 L 8.999984741210938 16 L 7.599990844726563 14.5999755859375 L 13.19999694824219 9 L 0 9 L 0 7 L 13.19998168945313 7 L 7.5999755859375 1.4000244140625 L 8.999984741210938 0 Z" />
              </svg>
            </div>
          </Link>
        </div>
      </Reveal>
    </div>
  )
}
export default Story
